import DatePicker from 'vue2-datepicker';
import commonHelper from '@/app/utility/common.helper.utility';
import appStrings from '@/app/utility/string.utility';
import { required, minLength, email } from 'vuelidate/lib/validators';
export default {
  name: 'LeaseAgreementDetails',
  props: ['leaseAgreementRowDtls'],
  components: {
    DatePicker
  },
  data() {
    return {
      loader: false,
      isSuccess: false,
      showAlert: false,
      responseMsg: '',
      unsubscribe: null,
      aggrementId: 0,
      lessorName: null,
      lessorAddress1: null,
      lessorAddress2: null,
      lessorCountry: { value: null, text: null },
      lessorState: { value: null, text: null },
      lessorCity: null,
      lessorPincode: null,
      lessorPanNum: null,
      lessorAuthPerson: null,
      lessorAuthDesig: null,
      lessorEmail: null,
      lesseeName: null,
      lesseeAuthPerson: null,
      lesseeAuthDate: null,
      lesseeEmail: null,
      leaseLocLegalEntity: { value: null, text: null },
      leaseLocName: null,
      leaseLocAddress1: null,
      leaseLocAddress2: null,
      leaseLocCountry: { value: null, text: null },
      leaseLocState: { value: null, text: null },
      leaseLocCity: null,
      leaseLocPincode: null,
      leaseLocCarpetArea: null,
      leaseLocChargeArea: null,
      leaseLocAreaUOM: { value: null, text: null },
      leaseRentAmt: null,
      leaseSecDepo: null,
      leaseEscal: null,
      leaseElecConection: null,
      leaseNoticePeriod: null,
      leaseJurisDic: null,
      leaseNatureOfSpace: null,
      leaseSpaceUsage: null,
      leaseRenFreq: null,
      leaseRentFreePeriod: null,
      leaseRentFreePeriodAddon: null,
      leaseRentFreePeriodPenalty: null,
      leaseStartDate: null,
      leaseEndDate: null,
      leaseMaintenCharge: null,
      leaseLockInPeriod: null,
      leaseRentDate: null,
      leaseExecutionDate: null,
      final: false,
      showValueSetModal: false,
      editMode: false,
      parent_value_set_id: null,
      dtlType: null,
      leaseAgrmntNum: null
    };
  },
  validations: {
    leaseLocLegalEntity: { text: { required } },
    lessorName: { required },
    lessorAddress1: { required },
    lessorCountry: { text: { required } },
    lessorState: { text: { required } },
    lessorCity: { required },
    lessorPincode: { required },
    lessorPanNum: { required, minLength: minLength(10) },
    lessorEmail: { required, email },
    lesseeEmail: { required, email },
    lessorAuthPerson: { required },
    lessorAuthDesig: { required },
    lesseeName: { required },
    lesseeAuthPerson: { required },
    lesseeAuthDate: { required },
    leaseLocName: { required },
    leaseLocAddress1: { required },
    leaseLocCountry: { text: { required } },
    leaseLocState: { text: { required } },
    leaseLocCity: { required },
    leaseLocPincode: { required },
    leaseLocCarpetArea: { required },
    leaseLocChargeArea: { required },
    leaseLocAreaUOM: { text: { required } },
    leaseRentAmt: { required },
    leaseSecDepo: { required },
    leaseEscal: { required },
    leaseElecConection: { required },
    leaseNoticePeriod: { required },
    leaseJurisDic: { required },
    leaseNatureOfSpace: { required },
    leaseSpaceUsage: { required },
    leaseRenFreq: { required },
    leaseRentFreePeriod: { required },
    leaseRentFreePeriodAddon: { required },
    leaseRentFreePeriodPenalty: { required },
    leaseStartDate: { required },
    leaseEndDate: { required },
    leaseMaintenCharge: { required },
    leaseLockInPeriod: { required },
    leaseRentDate: { required },
    leaseExecutionDate: { required }
  },
  mounted() {
    if (this.leaseAgreementRowDtls) {
      this.getLeaseAgreementDetailsById();
    } else {
      this.editMode = true;
    }
    this.getUomClass();
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && this.editMode) {
          // this.isPanValid();
          this.addEditLeaseAgreementDetails();
        }
        if (actionName === 'update' || actionName === 'edit') {
          this.editModeLeaseDetails()
          // this.editMode = true;
        }
      }
    });
  },
  methods: {
    isPanValid() {
      const validPan = commonHelper.isValidPanNumber(this.lessorPanNum);
      if (validPan) {
        this.addEditLeaseAgreementDetails();
      }
    },
    editModeLeaseDetails() {
      if (this.leaseAgreementRowDtls.approval_status === "DRAFT") {
        this.editMode = true;
        this.isAddressSaved = false;
      } else {
        alert("Record is in " + this.leaseAgreementRowDtls.approval_status + " Stage");
      }
    },
    getLeaseAgreementDetailsById() {
      const payload = {
        agreement_id: this.leaseAgreementRowDtls.agreement_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getLeaseAgreementDetailsById', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            const result = resp.data.data;
            this.aggrementId = result.agreement_id;
            this.lessorName = result.lessor;
            this.lessorAddress1 = result.lessor_address_1;
            this.lessorAddress2 = result.lessor_address_2;
            this.lessorCountry.text = result.lessor_country;
            this.lessorCountry.value = result.lessor_country_id;
            this.lessorState.text = result.lessor_state;
            this.lessorState.value = result.lessor_state_id;
            this.lessorCity = result.lessor_city;
            this.lessorPincode = result.lessor_pincode;
            this.lessorPanNum = result.lessor_pan_number;
            this.lessorAuthPerson = result.lessor_authorized_person;
            this.lessorAuthDesig = result.lessor_authorized_person_designation;
            this.lessorEmail = result.lessor_email_address;
            this.lesseeName = result.lessee;
            this.lesseeAuthPerson = result.lessee_authorized_person;
            this.lesseeAuthDate = result.lessee_authorization_date;
            this.lesseeEmail = result.lessee_email_address;
            this.leaseLocLegalEntity.text = result.le_name;
            this.leaseLocLegalEntity.value = result.le_id;
            this.leaseLocName = result.location_name;
            this.leaseLocAddress1 = result.lease_location_add1;
            this.leaseLocAddress2 = result.lease_location_add2;
            this.leaseLocCountry.text = result.lease_country;
            this.leaseLocCountry.value = result.lease_country_id;
            this.leaseLocState.text = result.lease_state;
            this.leaseLocState.value = result.lease_state_id;
            this.leaseLocCity = result.lease_city;
            this.leaseLocPincode = result.lease_location_pincode;
            this.leaseLocCarpetArea = result.carpet_area;
            this.leaseLocChargeArea = result.chargeable_area;
            this.leaseLocAreaUOM.text = result.area_uom_meaning;
            this.leaseLocAreaUOM.value = result.area_uom;
            this.leaseRentAmt = result.rent_amount;
            this.leaseSecDepo = result.security_deposit;
            this.leaseEscal = result.escalation;
            this.leaseElecConection = result.elecricity_connection;
            this.leaseNoticePeriod = result.notice_period;
            this.leaseJurisDic = result.jurisdiction;
            this.leaseNatureOfSpace = result.nature_of_space;
            this.leaseSpaceUsage = result.space_usage;
            this.leaseRenFreq = result.renwal_freq;
            this.leaseRentFreePeriod = result.rent_free_period;
            this.leaseRentFreePeriodAddon = result.rent_free_period_addon;
            this.leaseRentFreePeriodPenalty = result.rent_free_period_penalty;
            this.leaseStartDate = result.lease_start_date;
            this.leaseEndDate = result.lease_end_date;
            this.leaseMaintenCharge = result.maintenance_charges;
            this.leaseLockInPeriod = result.lockin_period;
            this.leaseRentDate = result.rent_start_date;
            this.leaseExecutionDate = result.lease_execution_date;
            this.leaseAgrmntNum = result.lease_agreement_number;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    addEditLeaseAgreementDetails() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const payload = {
          agreement_id: this.aggrementId,
          lessee: this.lesseeName,
          le_id: this.leaseLocLegalEntity.value,
          lessor: this.lessorName,
          lessor_address_1: this.lessorAddress1 ,
          lessor_address_2: this.lessorAddress2 ? this.lessorAddress2 : "",
          lessor_country_id: this.lessorCountry.value,
          lessor_state_id: this.lessorState.value,
          lessor_city: this.lessorCity,
          lessor_pincode: this.lessorPincode,
          lease_location_add1: this.leaseLocAddress1,
          lease_location_add2: this.leaseLocAddress2 ? this.leaseLocAddress2 : "",
          lease_country_id: this.leaseLocCountry.value,
          lease_state_id: this.leaseLocState.value,
          lease_city: this.leaseLocCity,
          lease_location_pincode: this.leaseLocPincode,
          carpet_area: this.leaseLocCarpetArea,
          chargeable_area: this.leaseLocChargeArea,
          area_uom: this.leaseLocAreaUOM.value,
          lease_start_date: this.leaseStartDate,
          lease_end_date: this.leaseEndDate,
          rent_amount: this.leaseRentAmt ? this.leaseRentAmt : "",
          security_deposit: this.leaseSecDepo,
          escalation: this.leaseEscal,
          elecricity_connection: this.leaseElecConection,
          notice_period: this.leaseNoticePeriod,
          jurisdiction: this.leaseJurisDic,
          lease_execution_date: this.leaseExecutionDate,
          lessor_pan_number: this.lessorPanNum,
          lessor_authorized_person: this.lessorAuthPerson,
          lessor_authorized_person_designation: this.lessorAuthDesig,
          nature_of_space: this.leaseNatureOfSpace,
          space_usage: this.leaseSpaceUsage,
          renwal_freq: this.leaseRenFreq,
          rent_free_period: this.leaseRentFreePeriod,
          lessee_authorization_Person: this.lesseeAuthPerson,
          lessee_authorization_date: this.lesseeAuthDate,
          rent_start_date: this.leaseRentDate,
          rent_free_period_addon: this.leaseRentFreePeriodAddon,
          rent_free_period_penalty: this.leaseRentFreePeriodPenalty,
          maintenance_charges: this.leaseMaintenCharge,
          lockin_period: this.leaseLockInPeriod,
          lessee_email_address: this.lesseeEmail,
          lessor_email_address: this.lessorEmail,
          location_name: this.leaseLocName,
          lessee_authorized_person: this.lesseeAuthPerson,
          final_flag: this.final
        };
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/addEditLeaseAgreementDetails', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.editMode = false;
              this.isSuccess = true;
              this.aggrementId = response.data.data.agreement_id;
              this.leaseAgrmntNum = response.data.data.lease_agreement_number;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
          })
          .catch(() => {
            this.loader = false;
            this.showAlert = true;
            this.isSuccess = false;
            this.responseMsg = appStrings.autoFailedMsg;
          });
      }
    },
    upperCaseFunction(panNumber) {
      this.lessorPanNum = panNumber.toUpperCase();
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.leaseStartDate);
    },
    getUomClass() {
      this.$store
        .dispatch('template/getLOVBySetType', 'UOM_CLASS')
        .then(response => {
          if (response.status === 200) {
            const filterResult = response.data.data;
            const getValueAndText = filterResult.map(type => {
              return {
                value: type.value_code,
                text: type.value_meaning,
                detail_id: type.value_set_dtl_id
              };
            });
            this.intervalClass = getValueAndText.filter(
              item => item.value === 'AREA'
            );
          }
        });
    },
    openValueSetModal(vsetCode, dtlType) {
      this.vsetCode = vsetCode;
      this.dtlType = dtlType;
      this.vsetCode === 'UOM'
        ? (this.parent_value_set_id = this.intervalClass[0].detail_id)
        : (this.parent_value_set_id = null);
      if (
        this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE &&
        this.dtlType === 'lessorStateVal'
      ) {
        this.parent_value_set_id = this.lessorCountry.value;
      } else if (
        this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE &&
        this.dtlType === 'leaseLocStateVal'
      ) {
        this.parent_value_set_id = this.leaseLocCountry.value;
      } else if (
        (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CITY &&
          this.dtlType === 'lessorCityVal') ||
        (this.vsetCode === appStrings.VALUESETTYPE.SELECT_PINCODE &&
          this.dtlType === 'lessorPincodeVal')
      ) {
        this.parent_value_set_id = this.lessorState.value;
      } else if (
        (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CITY &&
          this.dtlType === 'leaseLocCityVal') ||
        (this.vsetCode === appStrings.VALUESETTYPE.SELECT_PINCODE &&
          this.dtlType === 'leaseLocPincodeVal')
      ) {
        this.parent_value_set_id = this.leaseLocState.value;
      }
      //  else if (this.vsetCode === 'UOM') {
      //   this.parent_value_set_id = this.intervalClass[0].detail_id;
      // }
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.leaseLocLegalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_COUNTRY) {
        if (this.dtlType === 'lessorCountryVal') {
          this.lessorCountry.text = item.value_code;
          this.lessorCountry.value = item.value_set_dtl_id;
          this.lessorState.text = null;
          this.lessorState.value = null;
          this.lessorCity = null;
          this.lessorPincode = null;
        } else if (this.dtlType === 'leaseLocCountryVal') {
          this.leaseLocCountry.text = item.value_code;
          this.leaseLocCountry.value = item.value_set_dtl_id;
          this.leaseLocState.text = null;
          this.leaseLocState.value = null;
          this.leaseLocCity = null;
          this.leaseLocPincode = null;
        }
      } else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_STATE) {
        if (this.dtlType === 'lessorStateVal') {
          this.lessorState.text = item.value_code;
          this.lessorState.value = item.value_set_dtl_id;
          this.lessorCity = null;
          this.lessorPincode = null;
        } else if (this.dtlType === 'leaseLocStateVal') {
          this.leaseLocState.text = item.value_code;
          this.leaseLocState.value = item.value_set_dtl_id;
          this.leaseLocCity = null;
          this.leaseLocPincode = null;
        }
      }
      //  else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_CITY) {
      //   if (this.dtlType === 'lessorCityVal') {
      //     this.lessorCity.text = item.value_code;
      //     this.lessorCity.value = item.value_set_dtl_id;
      //     this.lessorPincode = null;
      //   } else if (this.dtlType === 'leaseLocCityVal') {
      //     this.leaseLocCity.text = item.value_code;
      //     this.leaseLocCity.value = item.value_set_dtl_id;
      //     this.leaseLocPincode.text = null;
      //     this.leaseLocPincode.value = null;
      //   }
      // }
      // else if (this.vsetCode === appStrings.VALUESETTYPE.SELECT_PINCODE) {
      //   if (this.dtlType === 'lessorPincodeVal') {
      //     this.lessorPincode.text = item.value_code;
      //     this.lessorPincode.value = item.value_set_dtl_id;
      //   } else if (this.dtlType === 'leaseLocPincodeVal') {
      //     this.leaseLocPincode.text = item.value_code;
      //     this.leaseLocPincode.value = item.value_set_dtl_id;
      //   }
      // }
      else if (this.vsetCode === appStrings.VALUESETTYPE.UOM) {
        this.leaseLocAreaUOM.text = item.value_code;
        this.leaseLocAreaUOM.value = item.value_meaning;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.leaseLocName.text = item.value_code;
        this.leaseLocName.value = item.value_set_dtl_id;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    clearVsetValues(vsetCode, dtlType) {
      if (vsetCode === this.leaseLocLegalEntity.value) {
        this.leaseLocLegalEntity = {
          text: null,
          value: null
        };
      } else if (
        vsetCode === this.lessorCountry.value &&
        dtlType === 'lessorCountryVal'
      ) {
        this.lessorCountry.text = null;
        this.lessorCountry.value = null;
        this.lessorState.text = null;
        this.lessorState.value = null;
        this.lessorCity = null;
        this.lessorPincode = null;
      } else if (
        vsetCode === this.leaseLocCountry.value &&
        dtlType === 'leaseLocCountryVal'
      ) {
        this.leaseLocCountry.text = null;
        this.leaseLocCountry.value = null;
        this.leaseLocState.text = null;
        this.leaseLocState.value = null;
        this.leaseLocCity = null;
        this.leaseLocPincode = null;
      } else if (
        vsetCode === this.lessorState.value &&
        dtlType === 'lessorStateVal'
      ) {
        this.lessorState.text = null;
        this.lessorState.value = null;
        this.lessorCity = null;
        this.lessorPincode = null;
      } else if (
        vsetCode === this.leaseLocState.value &&
        dtlType === 'leaseLocStateVal'
      ) {
        this.leaseLocState.text = null;
        this.leaseLocState.value = null;
        this.leaseLocCity = null;
        this.leaseLocPincode = null;
      }
      // else if (
      //   vsetCode === this.lessorCity.value &&
      //   dtlType === 'lessorCityVal'
      // ) {
      //   this.lessorCity.text = null;
      //   this.lessorCity.value = null;
      //   this.lessorPincode.text = null;
      //   this.lessorPincode.value = null;
      // }
      // else if (
      //   vsetCode === this.leaseLocCity.value &&
      //   dtlType === 'leaseLocCityVal'
      // ) {
      //   this.leaseLocCity.text = null;
      //   this.leaseLocCity.value = null;
      //   this.leaseLocPincode.text = null;
      //   this.leaseLocPincode.value = null;
      // }
      //  else if (
      //   vsetCode === this.lessorPincode.value &&
      //   dtlType === 'lessorPincodeVal'
      // ) {
      //   this.lessorPincode.text = null;
      //   this.lessorPincode.value = null;
      // } else if (
      //   vsetCode === this.leaseLocPincode.value &&
      //   dtlType === 'leaseLocPincodeVal'
      // ) {
      //   this.leaseLocPincode.text = null;
      //   this.leaseLocPincode.value = null;
      // } else if (vsetCode === this.leaseLocAreaUOM.value) {
      //   this.leaseLocAreaUOM.text = null;
      //   this.leaseLocAreaUOM.value = null;
      // } else if (vsetCode === this.leaseLocName.value) {
      //   this.leaseLocName.text = null;
      //   this.leaseLocName.value = null;
      // }
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
