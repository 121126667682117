import leaseAgreementDetails from './leaseAgreementDetails';
import commonHelper from '@/app/utility/common.helper.utility';
import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility';
import previewDocLeaseAgreement from './previewDocLeaseAgreement';
import URL_UTILITY from '../../../utility/url.utility';
import reportModal from './reportModal';
export default {
  name: 'LeaseAgreement',
  watch: {
    currentPage: function() {
      this.getLeaseAgreementDetails();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getLeaseAgreementDetails();
    }
  },
  components: {
    leaseAgreementDetails,
    DatePicker,
    previewDocLeaseAgreement,
    reportModal
  },
  data() {
    return {
      loader: false,
      showModal: false,
      showOpenModal: false,
      showAlert: false,
      isSuccess: false,
      responseMsg: '',
      appStatus: { value: null, text: null },
      perPage: commonHelper.perPageRecord,
      pageOptions: commonHelper.getPageOption(),
      totalRows: null,
      currentPage: 1,
      lesseeName: null,
      leaseAgreementData: [],
      leaseAgreementFields: [
        { key: 'approval_status' },
        { key: 'le_name', label: 'Legal Entity' },
        { key: 'lease_agreement_number', label: 'Agreement Number' },
        { key: 'lessor' },
        { key: 'lessee' },
        { key: 'location_name' },
        { key: 'lease_start_date' },
        { key: 'lease_end_date' },
        {
          key: 'reports'
        },
        { key: 'preview', class: 'col-fix col-sm-1' }
      ],
      leaseAgreementNum: null,
      legalEntity: { value: null, text: null },
      leaseDateFrom: null,
      leaseDateTo: null,
      showValueSetModal: false,
      parent_value_set_id: null,
      leaseAgreementRowDtls: null,
      requestId: null,
      templateId: null,
      requestNumId: null,
      requestStatus: null,
      showReportModal: false
    };
  },
  mounted() {
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'add') {
          this.showModal = true;
        }
      }
    });
  },
  methods: {
    rowSelected(item) {
      this.leaseAgreementRowDtls = item;
      this.showHideLeaseAgrmntDtlsModal(true, item);
    },
    showHideLeaseAgrmntDtlsModal(flag, item) {
      this.leaseAgreementRowDtls = item;
      this.showModal = flag;
    },
    disabledDates(date) {
      return commonHelper.disabledDates(date, this.leaseDateFrom);
    },
    getLeaseAgreementDetails() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        approval_status: this.appStatus.value,
        le_id: this.legalEntity.value,
        lease_agreement_number: this.leaseAgreementNum,
        lessee_name: this.lesseeName,
        lease_start_date: this.leaseDateFrom,
        lease_end_date: this.leaseDateTo
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getLeaseAgreementDetails', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.leaseAgreementData = resp.data.data.page;
            this.leName = resp.data.data.page;
            this.leaseAgreementData.map(data => (data.preview = 'Preview'));
            this.totalRows = resp.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    showHideOpenModal(flag, item) {
      this.showOpenModal = flag;
      if (flag) {
        this.getRequestTemplateId(item);
      }
    },
    clearFilters() {
      this.lesseeName = null;
      this.appStatus = { value: null, text: null };
      this.legalEntity = { value: null, text: null };
      this.leaseDateTo = null;
      this.leaseDateFrom = null;
      this.totalRows = null;
      this.leaseAgreementNum = null;
      this.currentPage = 1;
      this.getLeaseAgreementDetails();
    },
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.showValueSetModal = true;
      this.parent_value_set_id = null;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.APPROVAL_STATUS_VSET) {
        this.appStatus = {
          text: item.value_meaning,
          value: item.value_code
        };
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.legalEntity = {
          text: item.value_code,
          value: item.value_set_dtl_id
        };
      }
    },
    clearVsetValues(vsetCode) {
      if (vsetCode === this.legalEntity.value) {
        this.legalEntity = {
          text: null,
          value: null
        };
      } else if (vsetCode === this.appStatus.value) {
        this.appStatus = {
          text: null,
          value: null
        };
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    getRequestTemplateId(item, index) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getRequestTemplateId')
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.requestId = resp.data.data.request_id;
            this.templateId = resp.data.data.template_id;
            this.saveSubmitRequest(item, index);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    saveSubmitRequest(item) {
      const payload = {
        admin_submit_req_details: [
          {
            request_num: 0,
            request_id: this.requestId,
            template_id: this.templateId,
            schedule_type: 'asap', //pass hardcode
            request_start_date: null,
            request_end_date: null,
            resubmit_interval: null,
            resubmit_interval_unit: null,
            day_of_month: null,
            day_of_week: null,
            output_format_id: 'PDF', //pass hardcode
            no_of_args: 25,
            request_parameter:
              item.le_id +
              ',' +
              item.lease_agreement_number +
              ',' +
              item.lessee +
              ',' +
              item.lease_start_date +
              ',' +
              item.lease_end_date +
              ',' +
              item.approval_status,
            sms_flag: false,
            email_flag: false,
            whatsapp_flag: false,
            child_req_count: null,
            email_comm_template_id: null,
            sms_comm_template_id: null,
            whatsapp_comm_template_id: null,
            argument1: item.le_id,
            argument2: item.lease_agreement_number,
            argument3: item.lessee,
            argument4: item.lease_start_date,
            argument5: item.lease_end_date,
            argument6: item.approval_status,
            argument7: null,
            argument8: null,
            argument9: null,
            argument10: null,
            argument11: null,
            argument12: null,
            argument13: null,
            argument14: null,
            argument15: null,
            argument16: null,
            argument17: null,
            argument18: null,
            argument19: null,
            argument20: null,
            argument21: null,
            argument22: null,
            argument23: null,
            argument24: null,
            argument25: null
          }
        ]
      };
      this.loader = true;
      this.$store
        .dispatch('template/saveSubmitRequest', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 201) {
            this.requestNumId = resp.data.data[0].id;
            // this.leaseAgreementData[index].request_id = this.requestNumId;
            // this.getLeaseAgreementDetails();
            this.getSubmitRequest(item);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getSubmitRequest(item) {
      this.loader = true;
      this.$store
        .dispatch('template/getSubmitRequest', this.requestNumId)
        .then(resp => {
          // this.loader = false;
          if (resp.status === 200) {
            this.requestStatus = resp.data.data[0].status;
            if (this.requestStatus === 'COMPLETED') {
              this.loader = false;
              this.updateLeaseAgreementReqId(item);
            } else if (this.requestStatus === 'ERROR') {
              this.loader = false;
              alert('Request in ERROR can not Preview');
            } else {
              setTimeout(() => {
                this.getSubmitRequest(item);
              }, 3000);
              // this.getSubmitRequest(item);
            }
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    updateLeaseAgreementReqId(item) {
      const payload = {
        request_id: this.requestNumId,
        agreement_id: item.agreement_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getAgreementReqId', payload)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.previewReport(this.requestNumId);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    // previewReport() {
    //   const payload = {
    //     request_id: this.requestNumId
    //   };
    //   this.loader = true;
    //   this.$store
    //     .dispatch('intWorkflow/getLeaseAgrmntRptPreview', payload)
    //     .then(resp => {
    //       this.loader = false;
    //       if (resp.status === 200) {
    //         const blob = resp.data;
    //         const linkUrl = window.URL.createObjectURL(
    //           new Blob([blob], { type: 'application/pdf' })
    //         );
    //         document.querySelector('#prev-docs').src = linkUrl;
    //       }
    //     })
    //     .catch(() => {
    //       this.loader = false;
    //     });
    // }
    previewReport(requestNumId) {
      const url = URL_UTILITY.previewLeaseAgrmntRptUrl + '/' + requestNumId;
      const token = sessionStorage.getItem('token');
      fetch(url, {
        method: 'get',
        headers: new Headers({
          Authorization: `Bearer ${token}`
        })
      })
        .then(response => {
          return response.blob();
        })
        .then(blob => {
          let imgLink = null;
          imgLink = window.URL.createObjectURL(
            new Blob([blob], { type: 'application/pdf' })
          );
          document.querySelector('#prev-docs').src = imgLink;
        });
    },
    showHideReportModal(flag) {
      this.showReportModal = flag;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
